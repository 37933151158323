
export default {
  common: {
    black: '#2D2D2D',
    white: '#fff',
    muted: '#D2D3D4'
  },
  primary: {
    main: '#E0301E', 
    mainExtended1: '#F9D6D2',
    mainExtended2: '#E44F3F',
    mainExtended3: '#E96E61',
    light: '#FFFFFF',
    dark: '#2D2D2D',
    darkExtended1: '#575757',
    darkExtended2: '#818181',
    darkExtended3: '#D5D5D5',
    yellow: '#FFB600',
    yellowExtended1: '#FFC42C',
    yellowExtended2: '#FFCE51',
    yellowExtended3: '#FFE08F',
    yellowExtended4: '#FFF1CD',
    rose: '#DB536A',
    roseExtended1: '#E27588',
    roseExtended2: '#E998A6',
    roseExtended3: '#F1BAC3',
    roseExtended4: '#F8DDE1',
    blue: '#3F88C5',
    blueExtended1: '#5C9ACD',
    blueExtended2: '#78ABD6',
    blueExtended3: '#A8C9E5',
    blueExtended4: '#D9E7F3',
    
  },
  secondary:{
    main: '#B579CE',
    blue: '#2E7BF8'
  },
  warning: {
    main: '#FFBF1F',
    mainExtended1: '#FFD979',
    mainExtended2: '#FFE5A5',
    mainExtended3: '#FFF2D2',
  },
  error: {
    main: '#D15548',
    mainExtended1: '#DC7F76',
    mainExtended2: '#E8AAA3',
    mainExtended3: '#F3D4D1',
  },
  success: {
    main: '#4EAD58',
    mainExtended1: '#7AC282',
    mainExtended2: '#A7D6AB',
    mainExtended3: '#D3EBD5',
  },
  inProgress: {
    main: '#3F88C5'
  },
  background:{
    main: '#DEDEDE',
    mainExtended1: '#BCBCBC',
    mainExtended2: '#8E9193',
    mainExtended3: '#A5A7A9',
    sideBar: '#404548',
    sideBarTitle: '#1E2428',
    sideBarActiveSubtitle: '#575b5e',
    light: '#F8F8F8',
    lightExtended1:'#f2f2f2',
    dark: '#6E6E6E',
    paleRed: "#FFEDED",
    paleRedExtended1: "#F8E0E0"
  },
  text: {
    primary: '#2D2D2D', 
    secondary: '#fff',
    error: '#D15548',
    sideBar: '#D2D3D4',
    title:'#2D2D2D',
    link: '#575757',
    backgroundText: '#464646',
    warningIcon: '#1E2420'
  },
};

import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

const Loader = props => {
  const { isFullscreen } = props;

  const wrapperClass = classNames({
    'loader-wrapper': true,
    'is-fullscreen': isFullscreen
  });

  return (
    <div className={wrapperClass}>
      <div className="loader">
        <ul>
          <li className="loader-dot" />
          <li className="loader-dot" />
          <li className="loader-dot" />
          <li className="loader-dot" />
          <li className="loader-dot" />
        </ul>
      </div>
    </div>
  );
};

Loader.propTypes = {
  isFullscreen: PropTypes.bool
};

Loader.defaultProps = {
  isFullscreen: true
}

export default Loader;

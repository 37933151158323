import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AuthContext from './AuthContext';

const AuthProvider = (props) => {
    const [authenticated, setAuthenticatedState] = useState(false);
    const [user, setUserState] = useState("");
    const [group, setGroupState] = useState("");
    const [tenant, setTenantState] = useState("");
    const [isSuperUser, setIsSuperUserState] = useState(false);
    const [email, setEmailState] = useState("");
    const [subscriptions, setSubscriptionsState] = useState("");
    const [acceptedEula, setAcceptedEulaState] = useState(false);

    const setUser = (user) => {
        setUserState(user);
    }

    const setGroup = (group) => {
        setGroupState(group)
    }

    const setAuthenticated = (authenticated) => {
        setAuthenticatedState(authenticated)
    }

     const setTenant = (tenant) => {
        setTenantState(tenant)
     }

     const setIsSuperUser = (superUser) => {
        setIsSuperUserState(superUser)
     }  
     
     const setEmail = (email) => {
        setEmailState(email)
     }

     const setSubscriptions = (subscriptions) => {
        setSubscriptionsState(subscriptions)
     }

     const setAcceptedEula = (acceptedEula) => {
        setAcceptedEulaState(acceptedEula)
     }

        return(
            <AuthContext.Provider
                value={{authenticated, 
                    user, 
                    group,
                    tenant,
                    isSuperUser,
                    email,
                    subscriptions,
                           setUser: setUser,
                           setGroup: setGroup, 
                           setAuthenticated: setAuthenticated,
                           setTenant: setTenant,
                           setIsSuperUser: setIsSuperUser,
                           setEmail: setEmail,
                           setSubscriptions: setSubscriptions,
                           setAcceptedEula: setAcceptedEula,
                }}
            >
                {props.children}
            </AuthContext.Provider>
        )
    }

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired
}

export default AuthProvider;
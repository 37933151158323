const awsconfig = {
  //  ...awsmobileImported,
  "aws_cognito_region": process.env.REACT_APP_AWS_REGION,
  "aws_user_pools_id":            window.TENANT_CONFIG === undefined ? process.env.REACT_APP_USERPOOL_ID :  window.TENANT_CONFIG.REACT_APP_USERPOOL_ID,
  "aws_user_pools_web_client_id": window.TENANT_CONFIG === undefined ? "null" : window.TENANT_CONFIG.REACT_APP_WEB_CLIENT_ID,
  "aws_cognito_identity_pool_id": window.TENANT_CONFIG === undefined ? "null" : window.TENANT_CONFIG.REACT_APP_IDENTITY_POOL,
  "oauth": {
    "domain": window.TENANT_CONFIG === undefined ? process.env.REACT_APP_PROTECT_USERPOOL_DOMAIN : window.TENANT_CONFIG.REACT_APP_PROTECT_USERPOOL_DOMAIN,
    "scope": [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin"
    ],
    "redirectSignIn": process.env.REACT_APP_RUN_LOCALLY === "TRUE" ? "http://localhost:3000/" : "https://" + window.location.href.split("/")[2] + "/",
    "redirectSignOut": process.env.REACT_APP_RUN_LOCALLY === "TRUE" ? "http://localhost:3000/" : "https://" + window.location.href.split("/")[2] + "/",
    "responseType": 'code'
  },
  "federationTarget": "COGNITO_USER_POOLS",
};

export default awsconfig;

import {fade} from '@material-ui/core/styles';
import palette from '../palette'


export default {
    root: {
        '&$focused $notchedOutline': {
            borderColor: "transparent",
          },
        '&$error $notchedOutline': {
        borderColor: palette.error.main,
        boxShadow: `${fade(palette.error.main, 0.25)} 0 0 0 0.2rem`,
        },
       
      },
      notchedOutline: {
        borderColor: palette.background.mainExtended1
      }
  };
  

 
import React from "react";
import { Router } from 'react-router-dom';
import Amplify from 'aws-amplify';
import { ThemeProvider } from "@material-ui/core/styles";

import loadable from "@loadable/component";
import pMinDelay from 'p-min-delay'

import Loader from "./components/Loader";
import AuthProvider from './components/AuthProvider';
import HistoryService from "./services/HistoryService";
import theme from './theme';
import awsconfig from './awsconfig';

Amplify.configure({
  ...awsconfig,
  Analytics: {
    disabled: true
  }});

const LoadableLayout = loadable(
  () => pMinDelay(import("./components/Layout"), 200),
  {
    fallback: <Loader isFullscreen />
  }
);

const App = () => {
  return (
    <div className="App">
      <LoadableLayout />
    </div>
  );
}

const AppWithRouter = () => (
  <ThemeProvider theme={theme}>
    <AuthProvider>
      <Router history={HistoryService}>
        <App />
      </Router>
    </AuthProvider>
  </ThemeProvider>
);

export default AppWithRouter;
import {createContext} from "react";

const AuthContext = createContext({
        authContext: {
            authenticated: false,
            user: "",
            group: "",
            subscriptions: "",
            acceptedEula: false,
        }
    }
)

export default AuthContext;

import palette from './palette';

export default {
  fontFamily: 'Helvetica Neue',
    h1:{
      fontWeight: '700',
      fontSize: '30px',
      lineHeight: '1.27',
    },
    h2: {
      fontWeight: '400',
    },
    body1: {
      color: palette.text.title,
      fontSize: '14px',
      
    },
    body2: {
      color: palette.text.title,
      fontSize: '12px',
      
    },
    button: {
      color: palette.text.title,
      fontSize: '14px'
    },
    caption: {
      color: palette.text.title,
      fontSize: '12px',    
    },
    overline: {
      color: palette.text.secondary,
      fontSize: '11px',
    }
};
